import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import thanksIllustration from "../images/thanks.svg";

function Thanks() {
  return (
    <Layout>
      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
        <SEO
          keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
          title="About"
        />

        <section className="flex flex-col items-center md:flex-row">
          <div className="md:w-2/3 md:mr-8">
            <h2 className="font-normal">
              {" "}
              Thank you for your message.
            </h2>
          </div>

          <figure className="w-2/3 md:w-1/3">
            <img alt="A dog relaxing" src={thanksIllustration} />
          </figure>
        </section>
      </main>
    </Layout>
  );
}

export default Thanks;
